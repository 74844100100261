.menu-toggle {
  position: relative;
  display: inline-block;
  border: none;
  width: 30px;
  height: 24px;
  outline: none;
  background: transparent;
  border-top: 3px solid;
  border-bottom: 3px solid;
  color: #ffffff;
  font-size: 0;
  transition: all 0.25s ease;
}
.menu-toggle:focus {
  outline: none;
}
.menu-toggle:active {
  outline: none;
}
.menu-toggle:hover {
  outline: none;
}
.menu-toggle:before, .menu-toggle:after {
  content: "";
  display: block;
  width: 100%;
  height: 3px;
  position: absolute;
  top: 50%;
  left: 50%;
  background: currentColor;
  transform: translate(-50%, -50%);
  transition: transform 0.25s ease;
}

button:hover {
  color: white;
}

button.act {
  border-color: transparent;
}
button.act:before {
  transform: translate(-50%, -50%) rotate(45deg);
}
button.act:after {
  transform: translate(-50%, -50%) rotate(-45deg);
}

.checkmark {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: block;
  stroke-width: 3;
  stroke: #222228;
  stroke-miterlimit: 10;
  box-shadow: inset 0px 0px 0px #222228;
  animation: fill 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #f7f7f7;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark__check {
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
@keyframes scale {
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}
@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 50px #f7f7f7;
  }
}
.admin-title {
  font-size: 21px;
  font-weight: bold;
  padding-bottom: 40px;
  width: 100%;
  float: left;
}

.creater-form-container {
  padding: 0 15%;
  min-height: 1000px;
  box-sizing: border-box;
  width: 100%;
  float: left;
}

.creater-form input {
  border: none;
  border-bottom: 2px solid #ececec;
  background: none;
  color: #ececec;
  height: 42px;
  outline: none !important;
  box-sizing: border-box;
  font-size: 18px;
  font-weight: bold;
  width: 100%;
  padding-left: 10px;
  margin-bottom: 20px;
}
.creater-form textarea {
  border: 2px solid #ececec;
  border-radius: 10px;
  box-sizing: border-box;
  background: none;
  width: 100%;
  font-weight: normal;
  padding: 20px;
  font-weight: 16px;
  margin-bottom: 30px;
  color: #ececec !important;
}

.file-upload {
  background: url("./img/upload.jpg") !important;
  background-position: 90% 30%;
  background-size: cover;
  width: 29% !important;
  border: none !important;
  float: right;
  border-radius: 5px;
  outline: none;
  cursor: pointer;
}

.file-upload::-webkit-file-upload-button {
  border: 1px solid #222228;
  font-size: 14px;
  display: none;
}

.creater-input-container {
  position: relative;
  width: 100%;
  float: left;
  box-sizing: border-box;
}
.creater-input-container img {
  width: 50%;
}

.preview-container {
  position: relative;
}
.preview-container img {
  width: 100%;
  position: relative;
  z-index: 100;
  height: auto;
}

.creater-input-label {
  position: absolute;
  left: 0px;
  top: 10px;
  font-size: 16px;
}

.input-manager {
  width: 100%;
  float: left;
  height: 50px;
}

.manager-btn {
  width: 23%;
  height: 40px;
  padding-top: 10px;
  box-sizing: border-box;
  font-size: 16px;
  float: left;
  border-radius: 5px;
  text-align: center;
  margin: 1%;
  border: 1px solid #ececec;
  cursor: pointer;
}
.manager-btn:hover {
  background: #f5f5f510;
}

.btn-creater {
  width: 20%;
  position: relative;
  left: 40%;
  top: 50px;
  box-sizing: border-box;
  height: 40px;
  padding-top: 10px;
  box-sizing: border-box;
  font-size: 16px;
  font-weight: bold;
  border-radius: 5px;
  float: left;
  text-align: center;
  margin: 1.5%;
  color: #222228;
  background: #ececec;
  cursor: pointer;
  transition: 0.1s ease;
}
.btn-creater:hover {
  transform: scale(1.05);
}

.delete-input {
  font-size: 21px;
  font-weight: bold;
  height: 32px;
  box-sizing: border-box;
  padding-top: 4px;
  text-align: center;
  width: 32px;
  border-radius: 2px;
  position: absolute;
  bottom: 25px;
  right: -70px;
  color: red;
  cursor: pointer;
}
.delete-input:hover {
  background: #f5f5f510;
}

.back-arrow {
  float: left;
  width: 50px;
}
.back-arrow img {
  width: 24px;
  height: 24px;
  transition: 0.1s ease;
}
.back-arrow img:hover {
  transform: translateX(-5px);
}

.creater-tools {
  padding-top: 100px;
  padding-bottom: 70px;
}

.language-controller {
  float: right;
}

.lang-control {
  cursor: pointer;
  float: left;
  padding: 0 10px;
}
.lang-control.act {
  font-weight: bold;
}

.create-btn {
  width: 40px;
  height: 40px;
  border-radius: 5px;
  border: 1px solid #ececec;
  position: absolute;
  top: -5px;
  right: 0;
  text-align: center;
  box-sizing: border-box;
  padding-top: 4px;
  cursor: pointer;
  color: #ececec;
  transition: 0.1s ease;
  font-size: 24px;
  opacity: 0.5;
}
.create-btn.act {
  opacity: 1;
}
.create-btn:hover {
  background: #f5f5f510;
}

.posts-table {
  display: none;
  transition: 0.1s ease;
  min-height: 100px;
  width: 100%;
}
.posts-table.act {
  display: block;
}

.table-item {
  width: 100%;
  float: left;
  border-bottom: 2px solid #ececec;
  padding-bottom: 15px;
  transition: 0.1s ease;
  padding-left: 10px;
  box-sizing: border-box;
  margin-bottom: 30px;
  font-size: 14px;
}

.table-col {
  float: left;
  text-align: left;
  padding-top: 14px;
  box-sizing: border-box;
}

.table-btn {
  float: right;
  width: 10%;
  text-align: center;
  border: 1px solid #ececec;
  border-radius: 5px;
  box-sizing: border-box;
  cursor: pointer;
  padding: 8px;
  height: 35px;
  margin-left: 20px;
  transition: 0.1s ease;
}
.table-btn:hover {
  background: #f5f5f510;
}

.table-col-1 {
  width: 5%;
}

.table-col-2 {
  width: 45%;
}

.table-col-3 {
  text-align: center;
  width: 10%;
}

.input-alert {
  font-size: 16px;
  width: 100%;
  float: left;
  color: red;
  text-align: center;
  display: none;
  position: relative;
  top: 100px;
}

.admin-nav-panel {
  float: left;
  width: 100%;
  border-bottom: 1px solid #7d7d7d;
  min-height: 50px;
  transition: 0.1s ease;
  margin-bottom: 18px;
}

.admin-panel-row {
  float: left;
  width: 100%;
  height: 40px;
  transition: 0.1s ease;
}

.second-panel {
  position: relative;
  overflow: hidden;
  height: 0px;
  transition: 0.1s ease;
}
.second-panel.act {
  height: 100%;
}

.row-item {
  float: left;
  width: 12%;
  cursor: pointer;
  font-size: 16px;
  padding: 10px;
  text-align: center;
  transition: 0.14s ease;
}
.row-item:hover {
  background: #f5f5f510;
}
.row-item.act {
  background: #ececec;
  color: #222228;
}

.admin-nav-panel {
  padding-bottom: 35px;
  width: 100%;
  float: left;
}

.article {
  padding: 15px;
  box-sizing: border-box;
  width: 100%;
}

.article-container {
  border-radius: 10px;
  box-shadow: 2px 2px 5px #0e0e10;
  width: 100%;
  height: 250px;
  position: relative;
  overflow: hidden;
}

.blog-col-2 {
  width: 50%;
  float: left;
}
.blog-col-2 img {
  width: auto;
  height: 100%;
  position: relative;
}
@media (max-width: 768px) {
  .blog-col-2 {
    width: 100%;
  }
}

.blog-col-1 {
  width: 25%;
  float: left;
}
.blog-col-1 img {
  height: 100%;
  position: relative;
  top: 0%;
}
@media (max-width: 1200px) {
  .blog-col-1 {
    width: 50%;
  }
}
@media (max-width: 650px) {
  .blog-col-1 {
    width: 100%;
  }
}

.article-title {
  font-size: 18px;
  font-weight: bold;
  position: absolute;
  z-index: 10;
  left: 20px;
  bottom: 20px;
}

.blog-fader {
  opacity: 0.9;
}

.blog {
  min-height: 1000px;
}

.blog-page {
  min-height: 1000px;
}

.blog-page-container {
  width: 100%;
  float: left;
  padding: 0 23%;
  padding-top: 12vh;
  box-sizing: border-box;
}
.blog-page-container img {
  width: 100%;
  margin-bottom: 20px;
}
@media (max-width: 768px) {
  .blog-page-container {
    padding: 0 15%;
    padding-top: 12vh;
  }
}
@media (max-width: 650px) {
  .blog-page-container {
    padding: 0 7%;
    padding-top: 12vh;
  }
}

.blog-page-title {
  text-align: left;
  font-size: 24px;
  font-weight: bold;
  padding-bottom: 40px;
}
@media (max-width: 650px) {
  .blog-page-title {
    font-size: 21px;
  }
}

.blog-page-preview {
  width: 100%;
  height: auto;
  margin-bottom: 40px;
}
.blog-page-preview img {
  width: 100%;
}

.content-title {
  width: 100%;
  float: left;
  padding-bottom: 25px;
  font-size: 18px;
  font-weight: bold;
}

.content-text {
  padding-bottom: 30px;
}

.content-img {
  width: 100%;
  float: left;
  padding-bottom: 40px;
}

.blog-page-content {
  width: 100%;
  float: left;
}

.loading-blog-page {
  width: 100%;
  float: left;
  padding-top: 12vh;
  text-align: center;
}

body {
  background: #222228;
  cursor: default;
  font-family: "Montserrat", sans-serif;
  color: #fff;
  overflow-x: hidden !important;
  text-shadow: 1px 2px 2px rgba(0, 0, 0, 0.1);
}
body.no-scroll {
  overflow-y: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

.navigation-bar-item {
  float: left;
}

.back-arrow {
  float: left;
}

.transition-arrow {
  float: left;
}

.common-back {
  padding-right: 10px;
  position: relative;
  top: 2px !important;
}

.cool-desctiption {
  float: left;
  width: 100%;
  padding-bottom: 30px;
}

.cool-description-item {
  padding: 12px;
  padding-bottom: 20px;
  width: 45%;
  float: left;
}
.cool-description-item img {
  width: 16px !important;
  float: left;
  margin: 0 10px;
  position: relative;
  transform: rotate(180deg);
  top: 5px;
}

.cool-digits {
  float: left;
  font-size: 21px;
  font-weight: bold;
}

.cool-description-text {
  float: left;
}

.big-digits {
  width: 100%;
  float: left;
  padding-bottom: 10px;
}

.common-text-container {
  position: relative;
  min-height: 350px;
  padding-bottom: 100px;
}

.common-text-col {
  box-sizing: border-box;
  width: 50%;
  min-height: 200px;
  padding: 0 30px;
  float: left;
}
@media (max-width: 768px) {
  .common-text-col {
    width: 100%;
  }
}

.common-text-element {
  padding-bottom: 30px;
  cursor: default;
  position: relative;
  color: #e0dede;
}

.cool-info-container {
  position: relative;
  margin-top: 100px;
  height: auto;
  display: flex;
}
@media (max-width: 1025px) {
  .cool-info-container {
    display: block;
  }
}

.cool-info-column-text {
  color: #d0cfcf;
  width: 50%;
  left: 50%;
  float: left;
}
@media (max-width: 1025px) {
  .cool-info-column-text {
    left: 0;
    width: 70%;
    padding: 0 15%;
  }
}

.cool-info-column-img {
  position: relative;
  color: #d0cfcf;
  min-width: 50%;
  left: 50%;
  float: left;
}
@media (max-width: 1025px) {
  .cool-info-column-img {
    min-width: 100%;
    left: 0;
  }
}

.cool-img {
  position: -webkit-sticky;
  position: sticky;
  top: 300px;
  height: 80vh;
}
@media (max-width: 1680px) {
  .cool-img {
    height: 100vh;
  }
}
@media (max-width: 1025px) {
  .cool-img {
    height: 55vh;
    width: 100%;
    top: 100px;
    position: relative;
  }
}
@media (max-width: 768px) {
  .cool-img {
    height: 75vh;
  }
}
.cool-img img {
  position: absolute;
  top: -5%;
  right: 94%;
  transform: translate(-50%, -17%);
  transition: 0.5s ease;
  max-width: 500px;
  height: 700px;
  width: auto;
}
@media (max-width: 1680px) {
  .cool-img img {
    right: 83%;
  }
}
@media (max-width: 1280px) {
  .cool-img img {
    right: 60%;
  }
}
@media (max-width: 1025px) {
  .cool-img img {
    top: -160px;
    right: 0%;
  }
}
@media (max-width: 768px) {
  .cool-img img {
    top: 0px;
    height: 550px;
    right: -10%;
  }
}
@media (max-width: 480px) {
  .cool-img img {
    right: -40%;
  }
}
.cool-img img:hover {
  transform: translate(-50%, -18%);
}

.form-for-key {
  position: absolute;
  width: 130%;
  bottom: -20px;
  left: -150%;
}

.cool-title {
  font-size: 21px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 25px;
  color: #e0dede;
}

.cool-text-element {
  padding-bottom: 30px;
  cursor: default;
  position: relative;
}

.main-wrapper {
  width: 80vw;
  position: relative;
  left: 10vw;
  float: left;
}
@media (max-width: 1025px) {
  .main-wrapper {
    width: 100%;
    left: 0vw;
  }
}

.block {
  box-sizing: border-box;
  padding: 50px 0;
}

.close-background {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
}

.pop-up-container {
  overflow: auto;
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1000;
}

.pop-up-price {
  box-sizing: border-box;
  padding: 25px;
  position: fixed;
  width: 60vw;
  height: 95vh;
  background: #222228;
  top: 5vh;
  left: 20vw;
}

.price-title {
  text-align: center;
  font-size: 21px;
  text-transform: uppercase;
}

.car-type {
  width: 100%;
  margin-top: 20px;
}

.car-type-item {
  opacity: 0.7;
  width: 20%;
  list-style: none;
  text-align: center;
  float: left;
  cursor: pointer;
  margin-bottom: 10px;
  transition: 0.1s ease;
}
.car-type-item:hover {
  opacity: 1;
}
.car-type-item.act {
  opacity: 1;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-track {
  background: #222228;
}

::-webkit-scrollbar-thumb {
  background: #3f3f47;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.price-content-item {
  display: none;
}

.active {
  display: block;
}

.catalog-catalog {
  min-heigth: 600px;
  float: left;
  width: 100%;
  margin-bottom: 100px;
}

.catalog-title {
  font-size: 24px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: left;
  margin-bottom: 50px;
}
@media (max-width: 768px) {
  .catalog-title {
    margin-bottom: 0px;
  }
}
@media (max-width: 480px) {
  .catalog-title {
    font-size: 20px;
    text-align: center;
  }
}
.cool-catalog-item {
  float: left;
  width: 30%;
  margin: 1.5%;
  box-sizing: border-box;
  border-radius: 10px;
  background: #222228;
  box-shadow: 5px 5px 15px #1a1a1f, -5px -5px 15px #2a2a31;
  padding: 0px;
  height: 375px;
  overflow: hidden;
  margin-bottom: 30px;
  transition: 0.3s ease;
  cursor: pointer;
  opacity: 0.8;
}
@media (max-width: 1680px) {
  .cool-catalog-item {
    height: 325px;
  }
}
@media (max-width: 1450px) {
  .cool-catalog-item {
    height: 315px;
  }
}
@media (max-width: 1366px) {
  .cool-catalog-item {
    height: 295px;
  }
}
@media (max-width: 1280px) {
  .cool-catalog-item {
    height: 270px;
  }
}
@media (max-width: 1200px) {
  .cool-catalog-item {
    width: 45%;
    height: 350px;
    margin: 1.5%;
  }
}
@media (max-width: 768px) {
  .cool-catalog-item {
    margin: 2.5% 15%;
    height: 420px;
    width: 70%;
  }
}
@media (max-width: 650px) {
  .cool-catalog-item {
    margin: 2.5% 10%;
    height: 390px;
    width: 80%;
  }
}
@media (max-width: 480px) {
  .cool-catalog-item {
    margin: 2.5% 5%;
    width: 90%;
  }
}
.cool-catalog-item:hover {
  transform: translateY(-10px);
}

.cool-catalog-container {
  overflow: hidden;
  border-radius: 10px;
}

.catalog-item-title {
  font-size: 24px;
  font-weight: bold;
  text-transform: uppercase;
  text-align: left;
  margin-bottom: 50px;
}
@media (max-width: 768px) {
  .catalog-item-title {
    text-align: center;
  }
}
@media (max-width: 480px) {
  .catalog-item-title {
    font-size: 18px;
  }
}

.catalog-item {
  float: left;
  width: 32%;
  margin: 1.5%;
  height: 320px;
  overflow: hidden;
  border-radius: 2px;
  transition: 0.1s ease;
  cursor: pointer;
  opacity: 0.8;
}
.catalog-item:hover {
  opacity: 1;
  transform: translateY(-3px);
}
@media (max-width: 1025px) {
  .catalog-item {
    width: 31.5%;
    margin-right: 1.5%;
  }
}
@media (max-width: 650px) {
  .catalog-item {
    width: 48%;
    margin-right: 2%;
  }
}
@media (max-width: 480px) {
  .catalog-item {
    width: 90%;
    margin-left: 5%;
    margin-right: 0%;
    text-align: center;
  }
}
@media (max-width: 360px) {
  .catalog-item {
    width: 95%;
    margin-left: 0%;
  }
}

.catalog-item-img {
  height: 60%;
  margin-bottom: 10px;
}
.catalog-item-img img {
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 10px;
  left: 0%;
}
@media (max-width: 650px) {
  .catalog-item-img img {
    height: 300px;
    width: auto;
    left: -15%;
  }
}

.catalog-item-component {
  width: 100%;
  height: 100%;
  position: relative;
  top: -20px;
  margin-bottom: 100px;
  float: left;
}

.catalog-item-main-img {
  width: 47.5%;
  margin-right: 2.5%;
  float: left;
}
.catalog-item-main-img img {
  width: 100%;
  height: 100%;
  margin-bottom: 30px;
}
@media (max-width: 1025px) {
  .catalog-item-main-img {
    width: 100%;
    margin-bottom: 50px;
  }
}

.item-text-row {
  margin-bottom: 30px;
}

.catalog-item-main-text {
  float: left;
  width: 50%;
  box-sizing: border-box;
  padding: 10px;
}
@media (max-width: 1025px) {
  .catalog-item-main-text {
    width: 100%;
  }
}

.catalog-item-text {
  font-size: 16px;
  padding: 5px 10px;
}
@media (max-width: 1025px) {
  .catalog-item-text {
    padding: 0 15px;
    font-size: 15px;
    padding-top: 15px;
    font-weight: 700;
    text-align: center;
  }
}

.price-content {
  margin-top: 30px;
  overflow: scroll;
  float: left;
  box-sizing: border-box;
  width: 100%;
  height: 700px;
}
.price-content img {
  width: 100%;
  float: left;
}

.btn-price {
  background: #ececec;
  padding: 10px 0px;
  color: #222228;
  width: 150px;
  text-shadow: none;
  border-radius: 3px;
  text-align: center;
  position: relative;
  opacity: 0.9;
  transition: 0.1s ease;
  top: 80px;
  cursor: pointer;
}
.btn-price:hover {
  opacity: 1;
}

.firstscreen-common {
  width: 100vw;
  height: 66vh;
  position: relative;
  top: -10px;
  left: -5px;
  background: #111;
  margin-bottom: 30px;
}
@media (max-width: 768px) {
  .firstscreen-common {
    height: 29vh;
  }
}

.fs-notcommon {
  height: 100vh;
}
@media (max-width: 1025px) {
  .fs-notcommon {
    height: 60vh;
  }
}
@media (max-width: 480px) {
  .fs-notcommon {
    height: 70vh;
  }
}
@media (max-width: 375px) {
  .fs-notcommon {
    height: 70vh;
  }
}
@media (max-width: 360px) {
  .fs-notcommon {
    height: 100vh;
  }
}

.fs-common-1 {
  background: url("./img/fs-3.jpg");
  background-size: cover;
  background-position: 90% center;
}

.fs-common-2 {
  background: url("./img/fs-2.jpg");
  background-size: cover;
  background-position: top left;
}

.fs-common-3 {
  background: url("./img/fs-1.jpg");
  background-size: cover;
  background-position: 90% center;
}

.fs-common-multimedia {
  background: url("./img/fs-4.jpg");
  background-size: cover;
  background-position: center 30%;
}

.fs-common-bmwkey {
  background: url("./img/fs-5.jpg");
  background-size: cover;
  background-position: center 30%;
}

.fs-common-display {
  background: url("./img/fs-6.jpg");
  background-size: cover;
  background-position: center 30%;
}

.fs-common-parking {
  background: url("./img/fs-7.jpg");
  background-size: cover;
  background-position: center 30%;
}

.fs-common-navigation {
  background: url("./img/fs-8.jpg");
  background-size: cover;
  background-position: center 30%;
}

.fs-common-coding {
  background: url("./img/fs-9.jpg");
  background-size: cover;
  background-position: center 30%;
}

.fs-common-support {
  background: url("./img/fs-10.jpg");
  background-attachment: fixed;
  background-size: cover;
  background-position: center 200%;
}

.fs-common-autopick {
  background: url("./img/fs-11.jpg");
  background-size: cover;
  background-position: center 30%;
}

.fs-common-contacts {
  background: url("./img/fs-12.jpg");
  background-size: cover;
  background-position: center 30%;
}

.fs-common-blog {
  background: url("./img/fs-13.jpg");
  background-size: cover;
  background-position: center 30%;
}

.fs-common-file-service {
  background: url("./img/fs-1.jpg");
  background-size: cover;
  background-position: center 30%;
}

.fs-common-other {
  background: url("./img/fs-14.jpg");
  background-size: cover;
  background-position: center 30%;
}

.text-container {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  padding: 0 13.5%;
  padding-top: 10%;
}
@media (max-width: 1025px) {
  .text-container {
    padding-top: 22%;
    padding-left: 0;
    padding-right: 0;
    text-align: center;
  }
}
@media (max-width: 650px) {
  .text-container {
    padding-top: 22%;
    padding-left: 0;
    padding-right: 0;
    text-align: center;
  }
}
@media (max-width: 480px) {
  .text-container {
    padding: 0 0%;
    padding-top: 32%;
  }
}
@media (max-width: 360px) {
  .text-container {
    padding-top: 36%;
  }
}
.text-container .fs-title {
  position: relative;
  font-size: 48px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 60px;
  padding-top: 40px;
  transition: 0.2s ease;
  font-weight: bold;
  z-index: 100;
}
@media (max-width: 1025px) {
  .text-container .fs-title {
    font-size: 72px;
    line-height: 1.5em;
  }
}
@media (max-width: 650px) {
  .text-container .fs-title {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .text-container .fs-title {
    font-size: 56px;
  }
}
@media (max-width: 480px) {
  .text-container .fs-title {
    font-size: 32px;
  }
}
@media (max-width: 360px) {
  .text-container .fs-title {
    font-size: 36px;
  }
}
.text-container .fs-subtitle {
  font-size: 24px;
  margin-bottom: 20px;
}
@media (max-width: 1025px) {
  .text-container .fs-subtitle {
    font-size: 32px;
  }
}
@media (max-width: 480px) {
  .text-container .fs-subtitle {
    font-size: 20px;
  }
}
.text-container .fs-text {
  font-size: 16px;
}
@media (max-width: 1025px) {
  .text-container .fs-text {
    font-size: 22px;
  }
}
@media (max-width: 768px) {
  .text-container .fs-text {
    font-size: 18px;
  }
}
@media (max-width: 480px) {
  .text-container .fs-text {
    font-size: 16px;
  }
}

.about-img-1 {
  height: 600px;
  background: url("./img/nav-item-1.jpg");
  background-size: cover;
  background-position: 30% center;
  margin-bottom: 50px;
}

.about-img-2 {
  height: 600px;
  background: url("./img/screen-key.jpg");
  background-size: cover;
  background-position: 30% center;
  margin-bottom: 50px;
}

.about-img-3 {
  height: 600px;
  background: url("./img/display-1.jpg");
  background-size: cover;
  background-position: 30% center;
  margin-bottom: 50px;
}

.about {
  float: left;
  min-height: 200px;
  margin-top: 30px;
  width: 100%;
  padding-bottom: 40px;
}
.about .about-row {
  margin-bottom: 40px;
  font-size: 16;
}
.about .about-row img {
  width: 100%;
  heigth: auto;
}
@media (max-width: 768px) {
  .about .about-row {
    font-size: 14px;
  }
}
@media (max-width: 480px) {
  .about .about-row {
    margin-bottom: 60px;
  }
}
@media (max-width: 375px) {
  .about .about-row {
    text-align: center;
  }
}

.two-row {
  width: 50%;
  float: left;
  box-sizing: border-box;
  padding: 0 30px;
}
@media (max-width: 768px) {
  .two-row {
    margin-bottom: 30px;
    padding: 0 20px;
    width: 100%;
  }
}
@media (max-width: 480px) {
  .two-row {
    padding: 0 10px;
  }
}

.three-row {
  width: 33%;
  float: left;
}
@media (max-width: 768px) {
  .three-row {
    width: 100%;
  }
}

.title {
  font-size: 24px;
  text-align: center;
  margin-bottom: 40px;
  font-weight: bold;
}
@media (max-width: 480px) {
  .title {
    font-size: 20px;
  }
}

.about-title {
  font-size: 24px;
  margin-bottom: 40px;
  font-weight: bold;
  text-align: center;
}
@media (max-width: 480px) {
  .about-title {
    font-size: 20px;
    right: 0;
    text-align: center;
  }
}

.catalog-row {
  margin-bottom: 50px;
  text-align: center;
}
@media (max-width: 768px) {
  .catalog-row p {
    margin-bottom: 20px;
  }
}
@media (max-width: 768px) {
  .catalog-row {
    font-size: 13px;
  }
}
@media (max-width: 480px) {
  .catalog-row {
    margin-bottom: 20px;
  }
}

.subtitle {
  text-align: center;
  margin-bottom: 30px;
}
@media (max-width: 768px) {
  .subtitle {
    font-size: 14px;
  }
}
@media (max-width: 480px) {
  .subtitle {
    font-size: 12px;
  }
}

.text-bg-container {
  position: relative;
  margin-bottom: 100px;
  width: 100vw;
  background: url("./img/bg-text.jpg");
  background-size: cover;
  background-position: top center;
  left: -12vw;
  float: left;
  height: 400px;
  box-sizing: border-box;
  padding: 0 30%;
  padding-top: 13%;
  text-align: center;
}

.text-bg {
  font-size: 18px;
}

.reason {
  position: relative;
  width: 100vw;
  height: 400px;
  margin-top: 100px;
  margin-bottom: 100px;
  left: -10vw;
  float: left;
  background: url("./img/bg-5reason.jpg");
  background-size: cover;
  background-position: 0% center;
  box-sizing: border-box;
  padding: 0 12vw;
}
@media (max-width: 1025px) {
  .reason {
    left: 0;
    padding: 0 5vw;
    height: 350px;
  }
}
@media (max-width: 650px) {
  .reason {
    height: 110vh;
    padding: 0 5vw;
    left: 0;
  }
}
.reason .reason-title {
  text-align: center;
  font-size: 32px;
  margin-bottom: 60px;
  margin-top: 45px;
}
@media (max-width: 480px) {
  .reason .reason-title {
    font-size: 20px;
  }
}
.reason .reason-item {
  width: 15%;
  margin: 0 2.5%;
  float: left;
}
@media (max-width: 650px) {
  .reason .reason-item {
    margin: 0%;
    width: 100%;
    margin-bottom: 60px;
  }
}
.reason .reason-img {
  text-align: center;
}
.reason .reason-img img {
  height: 64px;
  margin-bottom: 30px;
}
@media (max-width: 1025px) {
  .reason .reason-img img {
    height: 50px;
  }
}
.reason .reason-text {
  text-align: center;
}
@media (max-width: 1025px) {
  .reason .reason-text {
    font-size: 14px;
  }
}

.us {
  position: relative;
  top: -50px;
}

.us-item {
  width: 100%;
  text-align: center;
  font-size: 24px;
}
@media (max-width: 480px) {
  .us-item {
    font-size: 18px;
  }
}
.us-item li {
  list-style: none;
  margin-bottom: 15px;
}

.tunning-block {
  margin-top: 50px;
}

.tunning-row {
  padding: 0 30px;
  box-sizing: border-box;
  margin-bottom: 100px;
}
@media (max-width: 480px) {
  .tunning-row {
    text-align: center;
  }
}

.title-transform {
  transform: translateX(-15px);
  transition: 0.1s ease;
}
@media (max-width: 480px) {
  .title-transform {
    transform: none;
  }
}

.firstscreen-item {
  width: 26%;
  height: 100vh;
  float: left;
  transition: 0.5s;
  position: relative;
  animation-timing-function: ease;
}
@media (max-width: 1025px) {
  .firstscreen-item {
    width: 100%;
    height: 15vh;
  }
}
.firstscreen-item.active {
  width: 49%;
}
@media (max-width: 1025px) {
  .firstscreen-item.active {
    width: 100%;
    height: 70vh;
  }
}
.firstscreen-item.active .mask {
  background: rgba(0, 0, 0, 0.4);
}
@media (max-width: 375px) {
  .firstscreen-item.active .mask {
    background: rgba(0, 0, 0, 0.5);
  }
}
.firstscreen-item.active .fs-text-container {
  opacity: 1;
}
.firstscreen-item.active .fs-btn-container {
  transform: translateY(-100px);
}
@media (max-width: 480px) {
  .firstscreen-item.active .fs-btn-container {
    transform: translateY(30px);
  }
}
.firstscreen-item:hover {
  width: 49%;
}
@media (max-width: 1025px) {
  .firstscreen-item:hover {
    width: 100%;
    height: 70vh;
  }
}
.firstscreen-item:hover .mask {
  background: rgba(0, 0, 0, 0.4);
}
@media (max-width: 375px) {
  .firstscreen-item:hover .mask {
    background: rgba(0, 0, 0, 0.4);
  }
}
.firstscreen-item:hover .fs-text-container {
  opacity: 1;
}

.fs-1 {
  background: url("./img/fs-3.jpg");
  background-size: cover;
  background-position: top left;
}
@media (max-width: 768px) {
  .fs-1 {
    background-position: top center !important;
  }
}

.fs-2 {
  background: url("./img/fs-2.jpg");
  background-size: cover;
  width: 40%;
  background-position: top center;
}
@media (max-width: 1025px) {
  .fs-2 {
    width: 100%;
  }
}

.fs-3 {
  position: absolute;
  background: url("./img/fs-1.jpg");
  background-size: cover;
  background-position: top center;
  right: 0px;
}
@media (max-width: 1025px) {
  .fs-3 {
    position: relative;
  }
}

.fader {
  position: absolute;
  background: linear-gradient(0deg, #222228 0%, rgba(34, 34, 40, 0) 100%);
  bottom: -10vh;
  width: 100vw;
  transition: 0.1 ease;
  height: 150px;
}
@media (max-width: 1025px) {
  .fader {
    display: none;
  }
}

.fader-common {
  position: absolute;
  background: linear-gradient(0deg, #222228 0%, rgba(34, 34, 40, 0) 100%);
  bottom: -5px;
  width: 100vw;
  height: 150px;
}

.box-hover {
  cursor: pointer;
  position: relative;
  z-index: 2;
  transition: 0.1s ease;
}
.box-hover:hover {
  transform: scale(1.05);
}
@media (max-width: 1025px) {
  .box-hover:hover {
    transform: none;
  }
}

.contact-form {
  position: relative;
  top: 150px;
  width: 100%;
  min-height: 300px;
  box-sizing: border-box;
  float: left;
  padding: 0 12vw;
}
@media (max-width: 1025px) {
  .contact-form {
    top: 30vh;
  }
}
@media (max-width: 768px) {
  .contact-form {
    padding: 0 0;
    top: 30vh;
  }
}
@media (max-width: 480px) {
  .contact-form {
    top: 30vh;
  }
}

.contact-title {
  font-size: 20px;
  margin-bottom: 20px;
}

.form-contact-title {
  color: #606071;
  text-shadow: none;
}

.contacts {
  text-align: left;
  float: left;
  width: 30%;
}
@media (max-width: 1025px) {
  .contacts {
    width: 100%;
    text-align: center;
    margin-bottom: 100px;
  }
}

@media (max-width: 1025px) {
  .social-media-footer {
    display: none;
    width: 50%;
    float: left;
    position: relative;
    left: 25%;
    text-align: center;
  }
}
@media (max-width: 768px) {
  .social-media-footer {
    left: 25%;
  }
}
@media (max-width: 480px) {
  .social-media-footer {
    width: 80%;
    left: 25%;
  }
}

.contact-item {
  margin-bottom: 15px;
}
.contact-item img {
  width: 18px;
  position: relative;
  top: 5px;
  left: -10px;
}
.contact-item a {
  cursor: pointer;
  float: left;
  position: relative;
  left: -20px;
}
.contact-item a img {
  position: relative;
  left: 20px;
  margin-top: 15px;
  width: 24px;
  margin-right: 25px;
}

.form {
  border-radius: 32px;
  background: #222228;
  box-shadow: 5px 5px 24px #0e0e10, -5px -5px 24px #363640;
  position: relative;
  top: -60px;
  float: right;
  margin-right: 0%;
  box-sizing: border-box;
  padding: 30px;
  width: 60%;
}
@media (max-width: 1025px) {
  .form {
    width: 100%;
    text-align: center;
  }
}
@media (max-width: 768px) {
  .form {
    position: relative;
    float: left;
    width: 70%;
    left: 15%;
  }
}
@media (max-width: 480px) {
  .form {
    width: 95%;
    left: 2.5%;
  }
}
.form input {
  width: 80%;
  border-radius: 32px;
  background: #222228;
  box-shadow: inset 5px 5px 10px #222228, inset -5px -5px 10px #222228;
  margin: 0 auto;
  height: 32px;
  border: none;
  padding-left: 10px;
  margin-bottom: 10px;
  background: none;
  border-radius: 10px;
  outline: none !important;
  color: #ececec;
}

.input-submit {
  border-radius: 48px;
  background: linear-gradient(145deg, #222228, #222228) !important;
  box-shadow: 5px 5px 10px #151519, -5px -5px 10px #2f2f37 !important;
  width: 30%;
  color: #4f4f5d;
  cursor: pointer;
  transition: 0.2s ease;
}
.input-submit:hover {
  border-radius: 48px;
  background: linear-gradient(145deg, #1f1f24, #24242b) !important;
  box-shadow: 5px 5px 10px #151519, -5px -5px 10px #2f2f37 !important;
  color: #c2c2c2;
}
.input-submit:active {
  border-radius: 32px;
  background: #222228 !important;
  box-shadow: inset 5px 5px 10px #1e1e24, inset -5px -5px 10px #26262c !important;
}

.nav-footer {
  position: absolute;
  width: 101vw;
  min-height: 250px;
  margin-bottom: 100px;
  left: -10vw;
}
@media (max-width: 480px) {
  .nav-footer {
    min-height: 700px;
    margin-bottom: 300px;
    left: -10vw;
    width: 105vw;
  }
}

.nav-footer-item {
  width: 33%;
  float: left;
  min-height: 250px;
  text-align: center;
  padding-top: 110px;
  box-sizing: border-box;
  transition: 0.1s ease;
  opacity: 0.8;
  cursor: pointer;
  font-size: 28px;
  text-transform: uppercase;
  font-weight: bold;
}
@media (max-width: 768px) {
  .nav-footer-item {
    font-size: 21px;
    min-height: 200px;
    padding-top: 80px;
  }
}
@media (max-width: 480px) {
  .nav-footer-item {
    width: 100%;
    min-height: 125px;
    padding-top: 50px;
    font-size: 18px;
  }
}
.nav-footer-item:hover {
  opacity: 1;
}

.nfi-1 {
  position: relative;
  top: -20px;
}

.nav-item-bg-1 {
  background: url("./img/fs-3-footer.jpg");
  background-size: cover;
  background-position: top center;
}

.nav-item-bg-2 {
  background: url("./img/fs-2-footer.jpg");
  background-size: cover;
  background-position: top center;
}

.nav-item-bg-3 {
  background: url("./img/fs-1-footer.jpg");
  background-size: cover;
  background-position: top center;
}

.will-do {
  position: relative;
  float: left;
  width: 100%;
  min-height: 400px;
}
.will-do .will-do-item {
  width: 50%;
  float: left;
  box-sizing: border-box;
  padding: 0px 50px;
}
@media (max-width: 1025px) {
  .will-do .will-do-item {
    padding: 0 20px;
  }
}
@media (max-width: 768px) {
  .will-do .will-do-item {
    font-size: 12px;
  }
}
@media (max-width: 480px) {
  .will-do .will-do-item {
    padding: 0 10px;
    width: 100%;
  }
}
.will-do .action-item {
  margin-bottom: 15px;
}
.will-do .action-item img {
  width: 18px;
  height: 18px;
  position: relative;
  top: 3px;
  left: -7px;
}
@media (max-width: 768px) {
  .will-do .action-item img {
    width: 16px;
    height: 16px;
  }
}

.menu {
  position: fixed;
  top: 0vh;
  left: 0;
  height: 100vh;
  width: 6vw;
  z-index: 100;
  padding-top: 5vh;
  background: linear-gradient(90deg, #222228 0%, rgba(34, 34, 40, 0) 100%);
}
@media (max-width: 1680px) {
  .menu {
    position: fixed;
    top: 0vh;
    left: 0;
    height: 100vh;
    width: 5.5vw;
    z-index: 100;
    padding-top: 0vh;
    background: linear-gradient(90deg, #222228 0%, rgba(34, 34, 40, 0) 100%);
  }
}
@media (max-width: 1025px) {
  .menu {
    width: 10vw;
    height: 90vh;
    padding-top: 7vh;
    display: none;
  }
}
.menu .menu-item {
  height: 10%;
  position: relative;
  cursor: pointer;
  transition: 0.1s ease;
  opacity: 0.9;
  left: -5px;
}
@media (max-width: 1680px) {
  .menu .menu-item {
    height: 10%;
    position: relative;
    cursor: pointer;
    transition: 0.1s ease;
    opacity: 0.9;
    left: -5px;
  }
}
.menu .menu-item:hover {
  opacity: 1;
  box-shadow: 0px 2px 2px 1px rgba(0, 0, 0, 0.2);
  background: linear-gradient(90deg, #FFFFFF10 0%, rgba(255, 255, 255, 0.2) 100%);
  transform: translateX(5px);
}
.menu .menu-item:active {
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.2);
}
.menu .menu-item-text {
  font-size: 11px;
  text-align: center;
  position: absolute;
  bottom: 10px;
  width: 100%;
}
@media (max-width: 1680px) {
  .menu .menu-item-text {
    font-size: 10px;
    text-align: center;
    position: absolute;
    bottom: 12px;
    width: 100%;
  }
}
.menu .menu-item-img {
  width: 100%;
  text-align: center;
  box-sizing: border-box;
  padding-top: 12px;
}
.menu .menu-item-img img {
  transition: 0.1s ease;
  width: 36px;
  height: 36px;
}
@media (max-width: 1680px) {
  .menu .menu-item-img img {
    width: 30px;
    height: 30px;
  }
}

.menu-mobile {
  display: none;
  position: relative;
  top: 25vh;
  padding: 10px 0;
}
@media (max-width: 1025px) {
  .menu-mobile {
    display: block;
  }
}
.menu-mobile .menu-item-mobile {
  width: 33.33%;
  padding: 20px 0;
  text-align: center;
  float: left;
  position: relative;
  cursor: pointer;
  transition: 0.1s ease;
  opacity: 0.9;
  margin-bottom: 30px;
  box-shadow: 0px 2px 2px 1px rgba(0, 0, 0, 0.2);
  background: linear-gradient(90deg, #ffffff00 0%, rgba(255, 255, 255, 0.01) 100%);
}
.menu-mobile .menu-item-mobile:hover {
  opacity: 1;
}
.menu-mobile .menu-item-mobile:hover .menu-item-img-mobile img {
  transform: rotate(5deg);
}
.menu-mobile .menu-item-mobile:active {
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.2);
}
.menu-mobile .menu-item-text-mobile {
  font-size: 14px;
  text-align: center;
  width: 100%;
}
.menu-mobile .menu-item-img-mobile {
  width: 100%;
  text-align: center;
  box-sizing: border-box;
  padding-top: 12px;
}
.menu-mobile .menu-item-img-mobile img {
  margin-bottom: 30px;
  transition: 0.1s ease;
  width: 42px;
  height: 42px;
}

.login-container {
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
}
.login-container .login-form {
  width: 20vw;
  height: 40vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 10px 10px 5px rgba(0, 0, 0, 0.35);
  box-sizing: border-box;
  padding-top: 30px;
  border-radius: 2px;
  transition: 0.5s ease;
}
.login-container .login-form:hover {
  box-shadow: 0px 5px 5px 2px rgba(0, 0, 0, 0.35);
}
.login-container .login-form .login-title {
  text-align: center;
}
.login-container .login-form .login-input-container {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 10px 30px;
  box-sizing: border-box;
}
.login-container .login-form .login-input-container input {
  width: 100%;
  border: none;
  height: 30px;
  border-radius: 2px;
  padding-left: 10px;
  margin-bottom: 10px;
}
.login-container .login-form .login-input-container .btn-login {
  width: 50%;
  position: relative;
  left: 25%;
  text-align: center;
  cursor: pointer;
}

.navbar {
  position: absolute;
  top: 0;
  right: 3vw;
  z-index: 100;
  width: 90vw;
  height: 70px;
  box-sizing: border-box;
  padding-top: 20px;
  transition: 0.3s ease;
}
@media (max-width: 1025px) {
  .navbar {
    right: 0vw;
    width: 93vw;
  }
}
@media (max-width: 480px) {
  .navbar {
    right: 3.3vw;
    width: 93vw;
  }
}
.navbar .logo {
  float: left;
  cursor: pointer;
  position: relative;
  left: 0;
  top: -15px;
}
.navbar .logo img {
  height: 45px;
}
@media (max-width: 1025px) {
  .navbar .logo img {
    height: 36px;
  }
}
@media (max-width: 768px) {
  .navbar .logo img {
    height: 36px;
  }
}
.navbar .wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}
@media (max-width: 1025px) {
  .navbar .wrapper {
    width: 90%;
  }
}
.navbar .navbar-contacts {
  float: right;
  width: 60%;
}
@media (max-width: 1200px) {
  .navbar .navbar-contacts {
    width: 70%;
  }
}
@media (max-width: 1025px) {
  .navbar .navbar-contacts {
    display: none;
  }
}
.navbar .navbar-contacts-mobile {
  display: none;
  width: 80vw;
  height: 100vh;
  overflow: hidden;
  float: right;
  position: fixed;
  z-index: 105;
  right: -85vw;
  top: 0px;
  background: #18181d;
  transition: 0.5s ease;
}
.navbar .navbar-contacts-mobile.active {
  right: -20px;
}
@media (max-width: 1025px) {
  .navbar .navbar-contacts-mobile {
    display: block;
    width: 60vw;
  }
}
@media (max-width: 768px) {
  .navbar .navbar-contacts-mobile {
    width: 70vw;
  }
}
@media (max-width: 480px) {
  .navbar .navbar-contacts-mobile {
    width: 85vw;
  }
}
.navbar .mobile-trigger {
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.15);
  position: fixed;
  z-index: 110;
  top: 20px;
  left: 85vw;
  display: none;
}
@media (max-width: 1025px) {
  .navbar .mobile-trigger {
    display: block;
  }
}
.navbar .mobile-trigger img {
  width: 32px;
  height: 32px;
}
.navbar .social-media-mobile {
  padding: 15px;
}
.navbar .social-media-mobile img {
  height: 32px;
  height: 32px;
  margin-right: 20px;
  opacity: 0.8;
}
.navbar .navbar-alert {
  background: #4070f4;
  margin-right: 2%;
  float: left;
}
.navbar .navbar-auth {
  float: left;
  margin-right: 2%;
  opacity: 0.5;
  transition: 0.1s ease;
}
.navbar .navbar-auth:hover {
  opacity: 1;
}
@media (max-width: 1025px) {
  .navbar .navbar-auth {
    font-size: 12px;
    margin-right: 1%;
    display: none;
  }
}
.navbar .navbar-language-mobile {
  border: 1px solid #c2c2c2;
  height: 60px;
  width: 101%;
  position: relative;
  right: 3px;
}
.navbar .language-item-mobile {
  height: 60px;
  width: 31%;
  padding-top: 15px;
  float: left;
  text-align: center;
}
.navbar .navigation-mobile {
  width: 100%;
  margin-top: 10px;
}
.navbar .navbar-language {
  margin-left: 30px;
  text-align: center;
  float: right;
  height: 30px;
  width: 120px;
  position: relative;
}
@media (max-width: 1200px) {
  .navbar .navbar-language {
    margin-left: 0px;
  }
}
.navbar .navbar-language .language-item {
  display: inline-block;
  float: left;
  width: 30px;
  margin: 0 5px;
  cursor: pointer;
  transition: 0.1s ease;
  opacity: 0.5;
  position: relative;
}
.navbar .navbar-language .language-item:hover {
  opacity: 0.7;
}
.navbar .navbar-language .language-item.active {
  opacity: 1;
}
@media (max-width: 1200px) {
  .navbar .navbar-language .language-item {
    font-size: 14px;
    width: 20px;
  }
}
@media (max-width: 1025px) {
  .navbar .navbar-language .language-item {
    font-size: 14px;
    width: 25px;
  }
}
.navbar .navigation {
  float: right;
}
.navbar .navigation .nav-item {
  float: left;
  margin: 0 20px;
  cursor: pointer;
  transition: 0.1s ease;
  opacity: 0.6;
}
@media (max-width: 1200px) {
  .navbar .navigation .nav-item {
    margin: 0 15px;
  }
}
@media (max-width: 1025px) {
  .navbar .navigation .nav-item {
    margin: 0 20px;
    font-size: 14px;
  }
}
@media (max-width: 768px) {
  .navbar .navigation .nav-item {
    font-size: 14px;
    margin: 0 10px;
  }
}
.navbar .navigation .nav-item:hover {
  opacity: 1;
}
.navbar .social-media {
  float: right;
  margin-left: 30px;
}
@media (max-width: 1200px) {
  .navbar .social-media {
    margin-left: 0px;
  }
}
.navbar .social-media img {
  width: 24px;
  height: 24px;
  margin-left: 15px;
  opacity: 0.5;
  transition: 0.1s ease;
  cursor: pointer;
}
.navbar .social-media img:hover {
  opacity: 0.9;
}
@media (max-width: 1200px) {
  .navbar .social-media {
    display: none;
  }
}

.navigation-mobile {
  width: 100%;
}

.nav-item-mobile {
  width: 100%;
  box-sizing: border-box;
  height: 5vh;
  border: 1px solid #000;
  font-size: 14px;
  padding-top: 2vh;
  padding-left: 5%;
}
@media (max-width: 768px) {
  .nav-item-mobile {
    height: 6vh;
  }
}
.nav-item-mobile:hover {
  background: #1a1a1e;
}

.main-firstscreen {
  width: 100vw;
  height: 103vh;
  position: relative;
  top: -30px;
  left: -30px;
  background: #111;
}
@media (max-width: 1025px) {
  .main-firstscreen {
    height: 100vh;
    display: none;
  }
}

.firstscreen-item {
  width: 26%;
  height: 103vh;
  float: left;
  transition: 0.5s;
  position: relative;
  animation-timing-function: ease;
}
@media (max-width: 1025px) {
  .firstscreen-item {
    width: 100%;
    height: 20vh;
  }
}
.firstscreen-item.active {
  width: 49%;
}
@media (max-width: 1025px) {
  .firstscreen-item.active {
    width: 100%;
    height: 70vh;
  }
}
.firstscreen-item.active .mask {
  background: rgba(0, 0, 0, 0.2);
}
@media (max-width: 375px) {
  .firstscreen-item.active .mask {
    background: rgba(0, 0, 0, 0.5);
  }
}
.firstscreen-item.active .fs-text-container {
  opacity: 1;
}
.firstscreen-item.active .fs-btn-container {
  transform: translateY(-20px);
}
@media (max-width: 480px) {
  .firstscreen-item.active .fs-btn-container {
    transform: translateY(30px);
  }
}
.firstscreen-item:hover {
  width: 49%;
}
@media (max-width: 1025px) {
  .firstscreen-item:hover {
    width: 100%;
    height: 70vh;
  }
}
.firstscreen-item:hover .mask {
  background: rgba(0, 0, 0, 0.2);
}
@media (max-width: 375px) {
  .firstscreen-item:hover .mask {
    background: rgba(0, 0, 0, 0.4);
  }
}
.firstscreen-item:hover .fs-text-container {
  opacity: 1;
}

.fs-1 {
  background: url("./img/fs-3.jpg");
  background-size: cover;
  background-position: top left;
}

.fs-2 {
  background: url("./img/fs-2.jpg");
  background-size: cover;
  width: 40%;
  background-position: top center;
}
@media (max-width: 1025px) {
  .fs-2 {
    width: 100%;
  }
}

.fs-3 {
  position: absolute;
  background: url("./img/fs-1.jpg");
  background-size: cover;
  background-position: top center;
  right: 0px;
}
@media (max-width: 1025px) {
  .fs-3 {
    position: relative;
  }
}

.m-fs-1 {
  background: url("./img/m-fs-3.jpg");
  background-size: cover;
  background-position: top center;
}

.m-fs-2 {
  background: url("./img/m-fs-2.jpg");
  background-size: cover;
  background-position: top center;
}

.m-fs-3 {
  background: url("./img/m-fs-1.jpg");
  background-size: cover;
  background-position: top center;
}

.mobile-firstscreen {
  display: none;
  height: 50vh;
  width: 103%;
  background: #222228;
  float: left;
  margin-bottom: 100px;
  position: relative;
  z-index: 10;
  left: -5px;
  top: -7px;
}
@media (max-width: 1025px) {
  .mobile-firstscreen {
    display: block;
  }
}

.mobile-firstscreen-item {
  height: 80vh;
  width: 100%;
}

.mobile-fs-text-container {
  position: relative;
  z-index: 100;
  padding-top: 25%;
}
@media (max-width: 1025px) {
  .mobile-fs-text-container {
    padding-top: 15%;
  }
}
@media (max-width: 768px) {
  .mobile-fs-text-container {
    padding-top: 25%;
  }
}
@media (max-width: 480px) {
  .mobile-fs-text-container {
    padding-top: 35%;
  }
}

.mobile-fs-title {
  text-align: center;
  font-size: 48px;
  text-transform: uppercase;
  font-weight: bold;
}
@media (max-width: 480px) {
  .mobile-fs-title {
    font-size: 32px;
  }
}

.mobile-fs-btn-container {
  position: relative;
  box-sizing: border-box;
  padding-top: 30%;
  text-align: center;
}
@media (max-width: 1025px) {
  .mobile-fs-btn-container {
    padding-top: 40vh;
  }
}
@media (max-width: 768px) {
  .mobile-fs-btn-container {
    padding-top: 40vh;
  }
}
@media (max-width: 480px) {
  .mobile-fs-btn-container {
    padding-top: 40vh;
  }
}

.mobile-fs-btn {
  text-transform: uppercase;
  width: 30%;
  height: 50px;
  margin: 0 auto;
  box-sizing: border-box;
  background: #ececec;
  color: #222228;
  border-radius: 10px;
  font-weight: bold;
  padding-top: 15px;
  opacity: 0.9;
  transition: 0.1s ease;
}
.mobile-fs-btn:hover {
  opacity: 1;
}

.title-two {
  text-align: left;
  font-size: 24px;
  margin-bottom: 30px;
  font-weight: bold;
}

.mask {
  position: absolute;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: 0.3s;
  animation-timing-function: ease;
  background: rgba(0, 0, 0, 0.4);
}

.fs-text-container {
  position: absolute;
  opacity: 0.3;
  top: 17%;
  left: 6vw;
  transition: 0.3s;
  animation-timing-function: ease;
}
@media (max-width: 1025px) {
  .fs-text-container {
    width: 100%;
    text-align: center;
    left: 0;
  }
}
@media (max-width: 768px) {
  .fs-text-container {
    top: 25%;
  }
}

.fstc-1 {
  left: 8vw;
}
@media (max-width: 1025px) {
  .fstc-1 {
    left: 0;
  }
}

.fsbc-1 {
  left: 8vw !important;
}
@media (max-width: 1025px) {
  .fsbc-1 {
    left: 0 !important;
  }
}

.cool-fs-title {
  font-size: 36px;
  font-weight: bold;
  position: absolute;
  bottom: 20px;
  z-index: 10;
  text-transform: uppercase;
}
@media (max-width: 1025px) {
  .cool-fs-title {
    width: 100%;
    text-align: center;
  }
}
@media (max-width: 768px) {
  .cool-fs-title {
    font-size: 26px;
  }
}

.fs-title {
  font-size: 48px;
  font-weight: bold;
  text-transform: uppercase;
  transition: 0.1s ease;
  line-height: 60px;
  padding-top: 30px;
  width: 700px;
  letter-spacing: 0.01em;
}
@media (max-width: 1025px) {
  .fs-title {
    width: 100%;
    text-align: center;
  }
}
@media (max-width: 768px) {
  .fs-title {
    font-size: 48px;
    letter-spacing: 0;
    line-height: 40px;
  }
}
@media (max-width: 480px) {
  .fs-title {
    font-size: 32px;
    line-height: 30px;
  }
}

.fs-subtitle {
  margin-top: 24px;
  margin-bottom: 10px;
  font-size: 26px;
}
@media (max-width: 1025px) {
  .fs-subtitle {
    width: 100%;
    text-align: center;
  }
}
@media (max-width: 768px) {
  .fs-subtitle {
    font-size: 18px;
    letter-spacing: 0;
    line-height: 40px;
    margin-bottom: 30px !important;
    margin-top: 30px;
  }
}
@media (max-width: 480px) {
  .fs-subtitle {
    font-size: 16px;
  }
}

.fs-text {
  font-size: 20px;
  width: 500px;
}
@media (max-width: 1025px) {
  .fs-text {
    width: 100%;
    text-align: center;
  }
}
@media (max-width: 768px) {
  .fs-text {
    font-size: 16px;
  }
}
@media (max-width: 480px) {
  .fs-text {
    width: 100%;
    font-size: 13px;
  }
}

.fs-btn-container {
  transition: 0.5s;
  animation-timing-function: ease;
  position: absolute;
  z-index: 10;
  bottom: 100px;
  left: 6vw;
  transform: translateY(300px);
}
.fs-btn-container.mobile {
  width: 100%;
  padding: 0 10%;
}
@media (max-width: 1025px) {
  .fs-btn-container {
    width: 100%;
    padding: 0;
    left: 0;
  }
}

.fs-btn {
  font-size: 24px;
  float: left;
  margin-right: 60px;
  box-sizing: border-box;
  padding: 10px 35px;
  margin-bottom: 10px;
  border: 2px solid #ececec;
  border-radius: 5px;
  text-shadow: none;
  z-index: 0 !important;
  opacity: 0.8;
  transition: 0.1s;
  animation-timing-function: ease;
  cursor: pointer;
}
@media (max-width: 1025px) {
  .fs-btn {
    text-align: center;
    padding: 15px 0;
    width: 20vw;
    left: 40vw;
  }
}
@media (max-width: 768px) {
  .fs-btn {
    font-size: 18px;
    padding: 10px 30px;
  }
}
@media (max-width: 480px) {
  .fs-btn {
    padding: 15px 30px;
    width: 50vw;
    left: 25vw;
  }
}
.fs-btn:hover {
  opacity: 1;
}
.fs-btn.mobile {
  text-align: center;
  font-size: 18px;
  padding: 5px 10px;
  width: 30%;
  margin-right: 5%;
}

.btn-more {
  background: #ececec;
  color: #222228;
}

.catalog-back-trigger {
  float: left;
  margin-right: 20px;
  cursor: pointer;
  position: relative;
  top: -2px;
  transition: 0.1s ease;
}
@media (max-width: 768px) {
  .catalog-back-trigger {
    display: none;
    margin-right: 0px;
    margin-left: 20px;
  }
}
.catalog-back-trigger:hover {
  transform: translateX(-5px);
}
.catalog-back-trigger img {
  width: 32px;
  height: auto;
}

.big-text-container li {
  list-style: none;
  margin-bottom: 30px;
}

.pop-up-form-container {
  overflow: hidden;
  position: fixed;
  display: none;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1000;
}

.pop-up-form-content {
  background: #222228;
  position: absolute;
  width: 70vh;
  height: 70vh;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  box-sizing: border-box;
  padding: 30px;
  box-shadow: 0px 26px 29px -4px rgba(0, 0, 0, 0.35);
}
@media (max-width: 1025px) {
  .pop-up-form-content {
    width: 60vw;
    height: 40vh;
  }
}
@media (max-width: 768px) {
  .pop-up-form-content {
    height: 60vh;
  }
}
@media (max-width: 480px) {
  .pop-up-form-content {
    width: 90vw;
    height: 80vh;
  }
}
@media (max-width: 360px) {
  .pop-up-form-content {
    height: 95vh;
  }
}

.blur {
  filter: blur(0px);
}
.blur.active {
  filter: blur(4px);
}

.btn-pop-up-form {
  display: inline;
  padding: 15px 40px;
  color: #ececec;
  cursor: pointer;
  border-radius: 10px;
  background: linear-gradient(145deg, #18181c, #222228);
  box-shadow: 5px 5px 10px #18181c, -5px -5px 10px #222228;
}
.btn-pop-up-form:hover {
  background: linear-gradient(145deg, #222228, #24242b);
  box-shadow: 5px 5px 10px #18181c, -5px -5px 10px #222228;
}
.btn-pop-up-form:active {
  background: #222228;
  box-shadow: inset 5px 5px 10px #18181c, inset -5px -5px 10px #222228;
}

.choose-input {
  width: 100%;
  float: left;
  margin-bottom: 50px;
}
@media (max-width: 360px) {
  .choose-input {
    margin-bottom: 25px;
  }
}

.choose-input-title {
  font-size: 18px;
  font-weight: 700;
  text-align: center;
  margin-bottom: 25px;
}

.input-item {
  border-radius: 10px;
  text-align: center;
  font-size: 14px;
  float: left;
  padding: 10px;
  width: 22%;
  margin-left: 5%;
  cursor: pointer;
  transition: 0.1s ease;
  background: #222228;
  box-shadow: inset 5px 5px 10px #18181c, inset -5px -5px 10px #222228;
}
@media (max-width: 480px) {
  .input-item {
    width: 90%;
    margin-bottom: 15px;
  }
}
@media (max-width: 360px) {
  .input-item {
    margin-bottom: 7px;
  }
}
.input-item:hover {
  background: linear-gradient(145deg, #1f1f24, #222228);
  box-shadow: 5px 5px 10px #18181c, -5px -5px 10px #222228;
}
.input-item.active {
  background: linear-gradient(145deg, #1f1f24, #24242b);
  box-shadow: 5px 5px 10px #1c1c20, -5px -5px 10px #222228;
}

.input-common-container {
  background: #222228;
  width: 100%;
  float: left;
  position: relative;
  z-index: 10;
}

.btn-form-container {
  width: 100%;
  position: fixed;
  z-index: 5;
  bottom: 40px;
  left: 0px;
}

.btn-form {
  margin: 0 auto;
  width: 20%;
  text-align: center;
  padding: 15px 40px;
  color: #ececec;
  cursor: pointer;
  border-radius: 10px;
  background: linear-gradient(145deg, #24242b, #222228);
  box-shadow: 5px 5px 10px #18181c, -5px -5px 10px #2c2c34;
}
.btn-form:hover {
  background: linear-gradient(145deg, #1f1f24, #222228);
  box-shadow: 5px 5px 10px #18181c, -5px -5px 10px #222228;
}
.btn-form:active {
  background: #222228;
  box-shadow: inset 5px 5px 10px #18181c, inset -5px -5px 10px #222228;
}

.input-container {
  width: 100%;
  float: left;
  height: 60px;
  position: relative;
  margin-bottom: 20px;
}
@media (max-width: 360px) {
  .input-container {
    height: 45px;
  }
}
.input-container textarea {
  width: 100%;
  margin-top: 10px;
  border: none;
  border-radius: 10px;
  color: #ececec;
  outline: none;
  padding: 20px;
  box-sizing: border-box;
  background: #222228;
  box-shadow: inset 5px 5px 10px #18181c, inset -5px -5px 10px #222228;
}
.input-container .label {
  position: relative;
  left: 10px;
  top: -10px;
  font-size: 14px;
  transition: 0.1s ease;
}
@media (max-width: 360px) {
  .input-container .label {
    font-size: 12px;
  }
}
.input-container input {
  outline: none;
  border: none;
  padding-left: 15px;
  box-sizing: border-box;
  background: #222228;
  box-shadow: inset 5px 5px 10px #18181c, inset -5px -5px 10px #222228;
  border-radius: 10px;
  height: 40px;
  width: 100%;
  position: relative;
  color: #ececec;
}

.error-alert {
  color: red;
  display: none;
}

.final-check {
  padding-top: 100px;
}
.final-check svg {
  margin: 0 auto;
}

.puf-2 {
  display: none;
}

.puf-3 {
  display: none;
}

.thanks {
  padding-top: 40px;
  text-align: center;
}

.thanks-title {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 20px;
}

.btn-catalog-item-form {
  float: right;
  display: inline;
  margin-right: 30px;
  padding: 10px 40px;
  color: #ececec;
  font-size: 18px;
  cursor: pointer;
  border-radius: 10px;
  background: linear-gradient(145deg, #24242b, #222228);
  box-shadow: 5px 5px 10px #18181c, -5px -5px 10px #222228;
}
@media (max-width: 768px) {
  .btn-catalog-item-form {
    display: none;
  }
}
@media (max-width: 480px) {
  .btn-catalog-item-form {
    margin-top: 30px;
    margin-bottom: 30px;
    width: 60%;
  }
}
.btn-catalog-item-form:hover {
  background: linear-gradient(145deg, #1f1f24, #222228);
  box-shadow: 5px 5px 10px #18181c, -5px -5px 10px #222228;
}
.btn-catalog-item-form:active {
  background: #222228;
  box-shadow: inset 5px 5px 10px #18181c, inset -5px -5px 10px #222228;
}

.btn-catalog-item-form-mobile {
  display: none;
  float: left;
  margin-right: 30px;
  margin-top: 50px;
  padding: 10px 10vw;
  position: relative;
  color: #ececec;
  font-size: 18px;
  cursor: pointer;
  border-radius: 10px;
  background: linear-gradient(145deg, #24242b, #222228);
  box-shadow: 5px 5px 10px #18181c, -5px -5px 10px #2c2c34;
}
@media (max-width: 768px) {
  .btn-catalog-item-form-mobile {
    display: block;
  }
}
@media (max-width: 480px) {
  .btn-catalog-item-form-mobile {
    padding: 10px 20vw;
  }
}
.btn-catalog-item-form-mobile:hover {
  background: linear-gradient(145deg, #1f1f24, #222228);
  box-shadow: 5px 5px 10px #18181c, -5px -5px 10px #222228;
}
.btn-catalog-item-form-mobile:active {
  background: #222228;
  box-shadow: inset 5px 5px 10px #18181c, inset -5px -5px 10px #222228;
}

.form-service-name {
  width: 100%;
  text-align: center;
  font-size: 12px;
  float: left;
  margin-top: 10px;
}

.chooser-container {
  box-sizing: border-box;
  margin-bottom: 100px;
  position: relative;
  padding: 40px;
  padding-top: 15px;
  min-height: 200px;
  border-radius: 50px;
  background: #222228;
  box-shadow: 6px 6px 12px #1a1a1e, -6px -6px 12px #222228;
}
@media (max-width: 768px) {
  .chooser-container {
    float: left;
    padding: 10px;
    width: 100%;
    min-height: 300px;
    padding-bottom: 30px;
  }
}

.chooser-title {
  color: #ececec;
  font-size: 36px;
  font-weight: bold;
  position: relative;
  float: left;
  top: 20px;
  width: 25%;
}
@media (max-width: 768px) {
  .chooser-title {
    width: 100%;
    text-align: center;
    padding-bottom: 30px;
  }
}
@media (max-width: 480px) {
  .chooser-title {
    font-size: 21px;
  }
}

.chooser-input-container {
  width: 100%;
  float: right;
}
@media (max-width: 768px) {
  .chooser-input-container {
    width: 100%;
  }
}

.chooser-input {
  float: left;
  width: 30%;
  margin-top: 40px;
  box-sizing: border-box;
}
@media (max-width: 768px) {
  .chooser-input {
    padding: 0 10%;
    width: 100%;
    margin-top: 40px;
  }
}
.chooser-input select {
  height: 40px;
  border: 1px solid #ececec;
  background: none;
  border-radius: 5px;
  padding-left: 5px;
  color: #ececec;
  width: 85%;
}
@media (max-width: 768px) {
  .chooser-input select {
    float: left;
    width: 100%;
    height: 50px;
  }
}
.chooser-input select option {
  background: #222228;
  border: none;
}
@media (max-width: 768px) {
  .chooser-input select option {
    width: 40px;
  }
}

.chooser-error-alert {
  color: #ff6262;
  position: absolute;
  bottom: 30px;
  width: 200px;
  display: none;
}

.chooser-input-btn {
  width: 10%;
  float: right;
  margin-top: 70px;
  transition: 0.1s ease;
  cursor: pointer;
}
@media (max-width: 768px) {
  .chooser-input-btn {
    width: 40%;
    text-align: center;
    margin-top: 50px;
    border: 1px solid #ececec;
    border-radius: 10px;
    margin-right: 30%;
    padding: 10px 0;
  }
}
.chooser-input-btn:hover {
  transform: translateX(5px);
}
.chooser-input-btn:active {
  transform: translateX(0px);
}
.chooser-input-btn img {
  width: 40%;
  heigth: auto;
  transform: rotate(180deg);
}
@media (max-width: 768px) {
  .chooser-input-btn img {
    width: 32px;
  }
}

.success-msg {
  display: none;
  position: absolute;
  bottom: -40px;
  left: 0;
  font-size: 14px;
}

.contact-col {
  margin-top: 50px;
  margin-bottom: 50px;
}
.contact-col li {
  list-style: none;
  margin-bottom: 10px;
}

.cont-col-title {
  font-weight: bold;
  font-size: 1.1em;
  margin-bottom: 20px;
}

.carpage-numbers {
  padding: 0px 100px;
}
.carpage-numbers li {
  width: 33.33%;
  box-sizing: border-box;
  list-style: none;
  float: left;
  border: 1px solid #ffffff10;
  text-align: center;
  padding-top: 10px;
  height: 40px;
}
@media (max-width: 1025px) {
  .carpage-numbers {
    padding: 0 60px;
  }
}
@media (max-width: 768px) {
  .carpage-numbers {
    padding: 0 10px;
  }
}
@media (max-width: 650px) {
  .carpage-numbers {
    font-size: 14px;
  }
}
@media (max-width: 480px) {
  .carpage-numbers {
    padding: 0 0px;
    heigth: 50px;
    padding-top: 5px;
    font-size: 10px;
  }
}

.charts {
  width: 100%;
  float: left;
  margin-top: 100px;
}
.charts img {
  heigth: auto;
  width: 100%;
}

.carpage-preview {
  text-align: center;
}

.fs-title-carpage {
  position: relative;
  font-size: 42px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 60px;
  margin-top: 100px;
  transition: 0.2s ease;
  font-weight: bold;
  z-index: 100;
  padding: 0 50px;
}
@media (max-width: 1025px) {
  .fs-title-carpage {
    padding: 0 50px;
    font-size: 36px;
    line-height: 1.5em;
  }
}
@media (max-width: 768px) {
  .fs-title-carpage {
    margin-top: 0px;
    padding: 0 30px;
    font-size: 24px;
  }
}
@media (max-width: 480px) {
  .fs-title-carpage {
    padding: 0 5px;
    font-size: 16px;
  }
}

.cool-about-section {
  float: left;
  margin-bottom: 50px;
  width: 100%;
}

.cool-about {
  width: 100%;
  float: left;
  margin-bottom: 20px;
}

.cool-about-row {
  width: 33%;
  float: left;
  padding: 0 20px;
  box-sizing: border-box;
  padding-bottom: 50px;
}
@media (max-width: 768px) {
  .cool-about-row {
    width: 100%;
  }
}
.cool-about-row img {
  width: 100%;
  border-radius: 5px;
}
@media (max-width: 768px) {
  .cool-about-row img {
    display: none;
  }
}

.cool-about-title {
  font-size: 18px;
  font-weight: bold;
  padding-bottom: 0px;
  padding-top: 0px;
}
@media (max-width: 1366px) {
  .cool-about-title {
    padding-top: 10px;
  }
}
@media (max-width: 1025px) {
  .cool-about-title {
    padding-bottom: 10px;
    font-size: 24px;
  }
}
@media (max-width: 768px) {
  .cool-about-title {
    text-align: center;
  }
}

.gearbox-item {
  padding: 10px;
  min-height: 270px;
  position: relative;
  border-radius: 10px;
  box-shadow: 5px 5px 10px #18181c, -5px -5px 10px #222228;
  cursor: pointer;
}
.gearbox-item:hover {
  box-shadow: 8px 8px 15px #18181c, -8px -8px 15px #222228;
}

.gearbox-title {
  font-size: 24px;
  font-weight: bold;
  text-shadow: 1px 3px 3px rgba(0, 0, 0, 0.3);
  position: absolute;
  bottom: 30px;
  left: 30px;
}

.gearbox-bmw {
  background: url("./img/bmw.jpg");
  background-size: cover;
  background-position: center center;
}

.gearbox-vag {
  background: url("./img/vag.jpg");
  background-size: cover;
  background-position: center center;
}

.uppercase {
  text-transform: uppercase;
}

.catalog-item-list ul {
  padding-left: 15px;
  list-style-position: outside;
}
.catalog-item-list li {
  margin-bottom: 20px;
}

.title-list {
  font-size: 21px;
  font-weight: 700;
  position: relative;
}

.admin-container {
  padding-top: 100px;
  width: 100%;
  float: left;
}

.editor-title {
  font-size: 24px;
  font-weight: bold;
  padding-bottom: 50px;
}

.others-catalog-item {
  padding-top: 20vh;
}
